<template>
  <b-modal
    id="modal-select2"
    title="Numéros de réservation Tour-opérateur"
    ok-title="Enregistrer"
    cancel-title="Annuler"
    ref="modalReservationNumber"
    cancel-variant="outline-secondary"
    size="md"
    @cancel="resetReservation"
    @close="resetReservation"
    @ok.prevent="submitReservationNumbers"
    no-close-on-backdrop
  >
    <div class="my-1">
      Réservation: <strong>#{{ currentReservation.reservation_number }}</strong><br>
      Contact principal: <strong>{{ mainContact }}</strong>
    </div>
    <div v-if="travelers.length !== 0">
      <span class="mt-1">Liste des voyageurs</span><br>
      <ul>
        <li v-for="traveler in travelers" :key="traveler">
          {{ traveler }}
        </li>
      </ul>
    </div>

    <b-form>
      <b-row v-for="(reservationNumber, i) in reservationNumbers" :key="i">
          <b-col md="5">
            <b-form-group
              label="Tour-opérateur"
              label-for="Tour-opérateur"
            >
              <b-form-input v-model="reservationNumber.operator" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Numéro de réservation"
              label-for="Numéro de réservation"
            >
            <b-form-input v-model="reservationNumber.value" />
            </b-form-group>
          </b-col>
          <b-col md="1" class="my-auto px-0">
             <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click="removeBookingNumber(i)"
                >
                  <feather-icon icon="Trash2Icon"/>
                </b-button>
          </b-col>
      </b-row>
      <b-row>
        <b-col md="11"></b-col>
        <b-col md="1" class="my-auto px-0">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon rounded-circle"
            @click="addBookingNumber">
            <feather-icon icon="PlusIcon"/>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
  import BCardCode from '@core/components/b-card-code'
  import {
    BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormRadio,
    BFormTextarea,
    BTabs,
    BFormFile,
    BFormCheckboxGroup,
    BTab,
    BFormInvalidFeedback,
    BFormRadioGroup,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import {ref, watch, computed} from '@vue/composition-api'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    export default {
      name: "ReservationNumberModal",
      components: {
        BCardCode,
        BButton,
        BModal,
        BForm,
        BFormInput,
        BCardText,
        BFormGroup,
        vSelect,
        BRow,
        BFormRadioGroup,
        BCol,
        BInputGroup,
        BFormCheckbox,
        BFormCheckboxGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BTabs,
        BFormFile,
        BTab,
        BFormInvalidFeedback,
        ToastificationContent,
        BFormRadio
      },
      directives: {
        'b-modal': VBModal,
        Ripple,
      },
      props:['currentReservation', 'mainContact'],
      setup(props) {
        let reservationNumbers = ref()

        watch(() => props.currentReservation, () => {
          setReservationNumbers(props.currentReservation)
        });
        
        let setReservationNumbers = (reservation) => {
          if (reservation.booking_numbers.length > 0 )  {
            reservationNumbers.value = [...reservation.booking_numbers]
          } else { 
            reservationNumbers.value = [{operator: '', value: ''}]
          }
        }

        setReservationNumbers(props.currentReservation)
        
        return {
          reservationNumbers
        }

      },
      methods:{
          submitReservationNumbers() {
            this.$emit('submitReservationNumbers', this.currentReservation, this.reservationNumbers);
          },
          resetReservation() {
           this.$emit('resetReservation')
          },
          addBookingNumber() {
            this.reservationNumbers.push({
              operator: '',
              value: ''
            })
          },
          removeBookingNumber(index) {
            this.reservationNumbers.splice(index, 1);
          }
      },
      computed: {
        travelers() {
          let travelers = []
          this.currentReservation.package_reservations.forEach(x => {
            x.travelers.forEach(y => {
              travelers.push(y.first_name + " " + y.last_name)
            })
          })
          return travelers.sort();
        }
      }
    }
</script>

<style scoped>

</style>
