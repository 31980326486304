<template>
  <b-media class="mb-2">
    <template #aside>
      <b-avatar ref="previewEl" :src="source" variant="light-primary" size="90px" rounded/>
    </template>

    <h4 class="mb-1">{{ display_name }}</h4>
    <!--
    <b-button variant="primary" @click="$refs.refInputEl.click()">
      <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
      <span class="d-none d-sm-inline">{{ $t('button.upload') }}</span>
      <feather-icon icon="EditIcon" class="d-inline d-sm-none"/>
    </b-button>

    <b-button variant="outline-secondary" class="ml-1">
      <span class="d-none d-sm-inline">{{ $t('button.reset') }}</span>
      <feather-icon icon="TrashIcon" class="d-inline d-sm-none"/>
    </b-button>

    <b-card-text class="mt-0 font-small-2">{{ $t('user_profile.profile_image_requirements') }}</b-card-text>
    -->
  </b-media>
</template>

<script>
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import { BAvatar, BButton, BCardText, BMedia } from 'bootstrap-vue';

export default {
  name: 'user-profile-avatar',
  components: {
    BAvatar,
    BButton,
    BCardText,
    BMedia,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    display_name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      inputImageRenderer,
    };
  },
};
</script>
