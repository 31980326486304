<template>
  <b-modal
    ref="note-modal-reservation"
    @submitReservationNote="submitReservationNote"
    :title="'Note de réservation - #' + currentReservation.reservation_number"
    hide-footer
  >
    <div v-if="reservationNote">
      <p v-if="!toModifyNote">{{reservationNote}}</p>
      <textarea v-else v-model="reservationNote" cols="30" rows="5" class="form-control">
    </textarea>
    </div>
    <div v-else>
      <textarea v-model="reservationNote" cols="30" rows="5" class="form-control">
    </textarea>
    </div>
    <hr>
    <div class="d-flex justify-content-between" v-if="reservationNote && !toModifyNote">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        size="md"
        @click="$refs['note-modal-reservation'].hide()"
      >
        Fermer
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="md"
        @click="modifyNote()"
      >
        Modifier
      </b-button>
    </div>
    <div class="d-flex justify-content-end" v-else>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        size="md"
        @click="closeModalOrModifSection"
        class="mr-1"
      >
        Annuler
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="md"
        @click="submitReservationNote"
      >
        Enregistrer
      </b-button>
    </div>

  </b-modal>
</template>

<script>
  import BCardCode from '@core/components/b-card-code'
  import {
    BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText, BRow, BCol, BCard, BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormRadio,
    BFormTextarea,
    BTabs,
    BFormFile,
    BFormCheckboxGroup,
    BTab,
    BFormInvalidFeedback,
    BFormRadioGroup,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import reservationNote from "@/views/mixin/reservationNote";

  export default {
    name: "ReservationNoteModal",
    components: {
      BCardCode,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BCardText,
      BFormGroup,
      vSelect,
      BRow,
      BFormRadioGroup,
      BCol,
      BInputGroup,
      BFormCheckbox,
      BFormCheckboxGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BTabs,
      BFormFile,
      BTab,
      BFormInvalidFeedback,
      ToastificationContent,
      BFormRadio
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    props: ['currentReservation'],
    data() {
      return {
        toModifyNote: false,
        reservation_note: "",
      }
    },
    computed: {
      reservationNote: {
        get() {
          return this.currentReservation.notes;
        },
        set(newValue) {
          this.reservation_note = newValue
        }

      },
    },
    methods: {
      modifyNote() {
        this.reservation_note = this.currentReservation.notes
        this.toModifyNote = true
      },
      submitReservationNote() {
        this.toModifyNote = false;
        this.currentReservation.notes = this.reservation_note;
        this.$emit('submitReservationNote', this.currentReservation, this.reservation_note)

      },
      closeModalOrModifSection() {
        if (this.reservationNote) {
          this.toModifyNote = false;
        } else {
          this.$refs['note-modal-reservation'].hide();
        }
      }
    },
  }
</script>

<style scoped>

</style>
