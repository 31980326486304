import { useApiRequestMappers } from "@/shared/composables/use-api-request-mappers";
import { useApplicationContext } from "@/shared/composables/use-application-context";

export const useUserApi = () => {
  const { logger, httpClient } = useApplicationContext();
  const { keysToCamel, keysToSnakeCase } = useApiRequestMappers();

  const getUserProfile = async (userId) => {
    try {
      logger.info(`Getting user profile of user (${userId})`);
      const response = await httpClient.get(`/users/${userId}`);
      const userProfile = keysToCamel(response.data);

      logger.debug(`Retrieved user (${userId}) profile`, userProfile);
      return userProfile;
    } catch (error) {
      logger.error(`Failed to retrieve user (${userId}) profile`, error);
      throw  error;
    }
  };

  const updateUserProfile = async (id, data) => {

    const requestData = keysToSnakeCase(data);
    let role = requestData.role;
    delete requestData.role;
    delete requestData.avatar;
    delete requestData.id;

    try {
      logger.info(`Update user profile of user (${id})`);
      await httpClient.put(`/users/${id}/profile`, requestData);

      if (id != 'me') {
        await httpClient.put(`/users/${id}/roles`, {role});
      }

    } catch (error) {
      logger.error(`Failed to retrieve user (${id}) profile`, error);
      throw  error;
    }
  };

  const deleteUserProfile = async (id, data) => {

    const requestData = keysToSnakeCase(data);
    let role = requestData.role;
    delete requestData.role;
    delete requestData.avatar;
    delete requestData.id;

    try {
      logger.info(`Update user profile of user (${id})`);
      await httpClient.put(`/users/${id}/profile`, requestData);
      await httpClient.put(`/users/${id}/roles`, {role});

    } catch (error) {
      logger.error(`Failed to retrieve user (${id}) profile`, error);
      throw  error;
    }
  };


  return {
    getUserProfile,
    updateUserProfile,
    deleteUserProfile
  };
};
